import React, { useContext, Fragment, useEffect } from "react";
import { AprovacaoVendasAtendimentoContext } from "../AprovacaoVendasAtendimentoContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import GroupBox from "../../../../../Components/GroupBox/GroupBox";
import FormularioComplementar from "../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import AtendimentoAprovacao from "../Componente/AtendimentoAprovacao";


const PesquisarCarsystemApiConsultaHdi = () => {
  const { values, setValues, resetScreen, saveHandler } = useContext(AprovacaoVendasAtendimentoContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar exibirBotaoNovo={false} context={AprovacaoVendasAtendimentoContext}>
        <GroupBox titulo="Data Venda" className="col-12 col-md-4 form-group">
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="vendaDataHoraDe"
              value={values.searchData.vendaDataHoraDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="vendaDataHoraAte"
              value={values.searchData.vendaDataHoraAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
      </Pesquisar>

      <FormularioComplementar>
        <div className="col-12 text-right" style={{ marginBottom: 20 }}>
          <button
            disabled={values.itensModificados.length === 0}
            type="button"
            className={`btn ${values.itensModificados.length === 0
              ? "btn-secondary"
              : "btn-primary"
              } `}
            onClick={() => saveHandler()}
          >
            {values.itensModificados.length === 0
              ? "Nenhuma Venda Modificada"
              : `Salvar ${values.itensModificados.length} ${values.itensModificados.length > 1 ? "Vendas" : "Venda"
              } Modificada${values.itensModificados.length > 1 ? "s" : ""}`}
          </button>
        </div>
      </FormularioComplementar>


      {values.resultSearch.map((antendimentoParaAprovar) => (
        <AtendimentoAprovacao key={antendimentoParaAprovar.filaAtendimentoId} antendimentoParaAprovar={antendimentoParaAprovar} />
      ))}

    </Fragment>
  );
};

export default PesquisarCarsystemApiConsultaHdi;
