import React, { Fragment, useContext, useState, useEffect } from 'react';
import {
	ObterFabricantesFipe,
	ObterInformacoesVeiculoPorPlaca,
	ObterModelosFipeDoFabricantePorAno
} from "../../../../Services/Auto";
function initialState() {
	return {
		tipoVeiculo: 0,
		fabricantesFipe: [],
		modelosVeiculoFipe: []
	};
}
function isValidLicensePlate(plate) {
	// Adjust the regex based on the format you need (this example supports Brazilian and North American plates)
	const plateRegex = /^[A-Z]{3}\d{4}$|^[A-Z]{3}\d[A-Z]\d{2}$/;
	return plateRegex.test(plate.toUpperCase());
}

const FormularioLeadAuto = ({ context, onChangeEvent = (event) => { } }) => {

	useEffect(() => {
		if (isValidLicensePlate(atendimentoValues.dadosAtendimento.lead.placaVeiculo)) {
			obterInformacoesVeiculoPorPlaca(atendimentoValues.dadosAtendimento.lead.placaVeiculo);
		}
	}, []);


	const { atendimentoValues, setAtendimentoValues, showMessage } = useContext(context);
	const [formularioLeadAutoValues, SetFormularioLeadAutoValues] = useState(initialState());


	const onChangeEventGeral = (event) => {
		const { name, value } = event.target;
		SetFormularioLeadAutoValues({
			...formularioLeadAutoValues,
			[name]: value,
		})

		onChangeEvent(event);
	}

	const onChangeEventMarca = (event) => {
		const { value, selectedIndex } = event.target;
		const key = event.target.options[selectedIndex].getAttribute('data-key');

		SetFormularioLeadAutoValues({
			...formularioLeadAutoValues,
			marcaVeiculo: value,
			codigoMarcaVeiculo: key,
			modelosVeiculoFipe: []
		})

		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				lead: {
					...atendimentoValues.dadosAtendimento.lead,
					marcaVeiculo: value,
					modeloVeiculo: null,
					anoFabricacaoVeiculo: null,
					anoModeloVeiculo: null,
				},
			},
		});
	}

	function obterFabricantesFipe(event) {
		const { value } = event.target;
		let tipoVeiculo = value;

		if (tipoVeiculo == 0) {
			return;
		}

		ObterFabricantesFipe(tipoVeiculo).then(function (response) {
			if (response.success) {
				let fabricantesFipe = response.fabricantesFipe;

				SetFormularioLeadAutoValues({
					...formularioLeadAutoValues,
					fabricantesFipe
				});
			}
		});
	}

	function obterModelosFipeDoFabricantePorAno(event) {
		const { value } = event.target;
		if (!formularioLeadAutoValues.tipoVeiculo || !formularioLeadAutoValues.codigoMarcaVeiculo || !value) {
			return;
		}
		let tipoVeiculo = formularioLeadAutoValues.tipoVeiculo;
		let codigoMarcaVeiculo = formularioLeadAutoValues.codigoMarcaVeiculo;
		let anoModeloVeiculo = value;

		ObterModelosFipeDoFabricantePorAno(tipoVeiculo, codigoMarcaVeiculo, anoModeloVeiculo).then(function (response) {
			if (response.success) {
				let modelosFipe = response.modelosFipe;

				SetFormularioLeadAutoValues({
					...formularioLeadAutoValues,
					modelosVeiculoFipe: modelosFipe
				});
			}
		});
	}

	function obterDadosVeiculoPorPlaca(event) {
		const { value } = event.target;
		obterInformacoesVeiculoPorPlaca(value);
	}

	function obterInformacoesVeiculoPorPlaca(placa) {
		ObterInformacoesVeiculoPorPlaca(placa).then(async function (response) {
			if (response.success) {
				let informacoesVeiculoPorPlaca = response.informacoesVeiculoPorPlaca;

				let tipoVeiculo = 0;
				if (informacoesVeiculoPorPlaca.tipoVeiculo == 'CAR') {
					tipoVeiculo = 1;
				}
				else if (informacoesVeiculoPorPlaca.tipoVeiculo == 'MOT') {
					tipoVeiculo = 2;
				}
				else {
					tipoVeiculo = 3;
				}

				let fabricantesFipe = [{ codigo: informacoesVeiculoPorPlaca.marca, descricao: informacoesVeiculoPorPlaca.marca }];

				SetFormularioLeadAutoValues({
					...formularioLeadAutoValues,
					tipoVeiculo,
					fabricantesFipe,
					modelosVeiculoFipe: [{ codigo: informacoesVeiculoPorPlaca.modelo, descricao: informacoesVeiculoPorPlaca.modelo }],
				})


				setAtendimentoValues({
					...atendimentoValues,
					dadosAtendimento: {
						...atendimentoValues.dadosAtendimento,
						lead: {
							...atendimentoValues.dadosAtendimento.lead,
							marcaVeiculo: informacoesVeiculoPorPlaca.marca,
							modeloVeiculo: informacoesVeiculoPorPlaca.modelo,
							anoFabricacaoVeiculo: informacoesVeiculoPorPlaca.anoFabricacao,
							anoModeloVeiculo: informacoesVeiculoPorPlaca.anoModelo,
						},
					},
				});
			} else {
				showMessage("Alerta", response.message)
			}
		});
	}

	return (
		<Fragment>
			{atendimentoValues.dadosAtendimento.produto.produtoFormAuxLead !== 'FORMLEADAUTO' ? null : (
				<Fragment>
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>
								Placa<span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								className="form-control placa_veiculo"
								name="placaVeiculo"
								value={atendimentoValues.dadosAtendimento.lead.placaVeiculo}
								onChange={onChangeEvent}
								onBlur={obterDadosVeiculoPorPlaca}
							/>
						</div>
					</div>
					<div className="col-12 col-md-3 form-group">
						<label>Tipo Veículo<span className="campoObratorio">*</span></label>
						<select
							className="form-control"
							name="tipoVeiculo"
							value={formularioLeadAutoValues.tipoVeiculo}
							onChange={onChangeEventGeral}
							onBlur={obterFabricantesFipe}
						>
							<option data-key="0" className="disabled" value="0" />
							<option data-key="1" value="1" >Automovel</option>
							<option data-key="2" value="2" >Motocicleta</option>
							<option data-key="3" value="3" >Utilitario</option>

						</select>
					</div>
					<div className="col-12 col-md-3 form-group">
						<label>Veículo Marca<span className="campoObratorio">*</span></label>
						<select
							className="form-control"
							name="marcaVeiculo"
							value={atendimentoValues.dadosAtendimento.lead.marcaVeiculo}
							onChange={onChangeEventMarca}
						>
							<option data-key="0" className="disabled" value="0" />
							{formularioLeadAutoValues.fabricantesFipe.map((fabricanteFipe) => (
								<option key={fabricanteFipe.codigo} data-key={fabricanteFipe.codigo} value={fabricanteFipe.descricao}>
									{fabricanteFipe.descricao}
								</option>
							))}
						</select>
					</div>
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>
								Ano Fabric.<span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								className="form-control numerico"
								maxLength="4"
								name="anoFabricacaoVeiculo"
								value={atendimentoValues.dadosAtendimento.lead.anoFabricacaoVeiculo}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>
								Ano Modelo<span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								className="form-control numerico"
								maxLength="4"
								name="anoModeloVeiculo"
								value={atendimentoValues.dadosAtendimento.lead.anoModeloVeiculo}
								onChange={onChangeEvent}
								onBlur={obterModelosFipeDoFabricantePorAno}
							/>
						</div>
					</div>

					<div className="col-12 col-md-3 form-group">
						<label>Veículo Modelo<span className="campoObratorio">*</span></label>
						<select
							className="form-control"
							name="modeloVeiculo"
							value={atendimentoValues.dadosAtendimento.lead.modeloVeiculo}
							onChange={onChangeEvent}
						>
							<option data-key="0" className="disabled" value="0" />
							{formularioLeadAutoValues.modelosVeiculoFipe.map((modeloVeiculoFipe) => (
								<option key={modeloVeiculoFipe.codigo} data-key={modeloVeiculoFipe.codigo} value={modeloVeiculoFipe.descricao}>
									{modeloVeiculoFipe.descricao}
								</option>
							))}
						</select>
					</div>

					<div className="col-12 col-md-4">
						<div className="form-group">
							<label>Tipo Utilização Dut<span className="campoObratorio">*</span></label>
							<select
								className="form-control"
								name="leadAutoTipoUtilizacaoDut"
								value={atendimentoValues.dadosAtendimento.lead.leadAutoTipoUtilizacaoDut}
								onChange={onChangeEvent}
							>
								<option className="disabled" value="" />
								{atendimentoValues.listaDut.map((dut) => (
									<option key={dut.dutCodigo} value={dut.dutCodigo}>
										{dut.dutDescricao}
									</option>
								))}
							</select>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default FormularioLeadAuto;
