import React, { Component } from 'react';
import $ from 'jquery';
import './Apresentacao.css';
import { Fragment } from 'react';
// import Termometro from "../../../Components/Graficos/Termometro/Termometro";
import IndicadoPor from "../../../Components/Lead/IndicadoPor/IndicadoPor"

export const showApresentacao = () => {
	window.scroll(0, 0);
	$('#modalApresentacaoLeadAtendimento').modal({
		backdrop: 'static',
		keyboard: false,
		show: true
	});
};
export const hideApresentacao = () => {
	$('#modalApresentacaoLeadAtendimento').modal('hide');
};

export default class Apresentacao extends Component {
	render() {
		return (
			<Fragment>
				<div
					className="modal fade"
					id="modalApresentacaoLeadAtendimento"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="modalApresentacaoLeadAtendimentoLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="modalApresentacaoLeadAtendimentoLabel">
									Atendimento
								</h5>
							</div>
							<div className="modal-body componenteAgendaRedirecionar_modalBody">
								<div className="row align-items-center">
									{/* <div className="col-12 col-md-4 text-center">
                    <Termometro
                      id="termometroApresentacao"
                      minValue={0}
                      maxValue={5}
                      currentValue={this.props.temperaturaValor}
                    />
                  </div> */}
									<div className="col">
										<div className="text-danger font-weight-bold text-uppercase">
											{this.props.temperaturaDescricao}
										</div>
										<div>{ }</div>
										{this.props.leadIdRenovacao || this.props.leadIdRenovacao > 0 ? (
											<div className="font-weight-bold text-orange">
												RENOVAÇÃO
											</div>
										) : null}
										{this.props.dataRecebimentoLead || this.props.horaRecebimentoLead ? (
											<div className="font-weight-bold">
												Recebimento:{' '}
												<span className="font-weight-normal">
													{' '}
													{this.props.dataRecebimentoLead} – {this.props.horaRecebimentoLead}
												</span>
											</div>
										) : null}
										{this.props.totalContatos &&
											this.props.totalContatos !== 0 &&
											this.props.totalContatos !== '0' ? (
											<div className="font-weight-bold">
												Nrs. contato:{' '}
												<span className="font-weight-normal">{this.props.totalContatos}</span>
											</div>
										) : null}
										{this.props.cidade || this.props.uf ? (
											<div className="font-weight-bold">
												{this.props.cidade}/{this.props.uf}
											</div>
										) : null}
										{this.props.dataVencimentoApolice ? (
											<div className="font-weight-bold">
												Vencimento Apólice:{' '}
												<span className="font-weight-normal">
													{this.props.dataVencimentoApolice}
												</span>
											</div>
										) : null}

										{this.props.tipoVeiculoDescricao ? (
											<div className="font-weight-bold">
												Tipo Veículo:{' '}
												<span className="font-weight-normal">
													{this.props.tipoVeiculoDescricao}
												</span>
											</div>
										) : null}

										{this.props.tipoNegocio ? (
											<div className="font-weight-bold">
												Tipo de Negócio:{' '}
												<span className="font-weight-normal">{this.props.tipoNegocio}</span>
											</div>
										) : null}
										{this.props.leadDataHoraAgendaContato ? (
											<div className="font-weight-bold">
												Preferência de Data e Horario para Contato:{' '}
												<span className="font-weight-normal">{this.props.leadDataHoraAgendaContato}</span>
											</div>
										) : null}
										{this.props.leadPreferenciaContato ? (
											<div className="font-weight-bold">
												Preferência de Contato:{' '}
												<span className="font-weight-normal">{this.props.leadPreferenciaContato}</span>
											</div>
										) : null}
										{this.props.leadObservacaoLead ? (
											<div className="font-weight-bold">
												Observação Lead:{' '}
												<span className="font-weight-normal">{this.props.leadObservacaoLead}</span>
											</div>
										) : null}
										{this.props.motivoReprovaVenda ? (
											<Fragment>
												<div class="alert alert-danger mt-2" role="alert">
													<h4 class="alert-heading">Não Aprovado</h4>
													<hr />
													<p class="text-left mb-0">Motivo:</p>
													<p class="text-justify mb-0">{this.props.motivoReprovaVenda}</p>
												</div>
											</Fragment>
										) : null}
										{this.props.lojaVirtualNome || this.props.lojaVirtualImagem ?
											<Fragment>
												<hr />
												<IndicadoPor nome={this.props.lojaVirtualNome} imagem={this.props.lojaVirtualImagem} />
											</Fragment> : null
										}

									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-default" onClick={this.props.onClick}>
									Iniciar Atendimento
								</button>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
