import $ from 'jquery';
import React, { Fragment, useContext, useState } from "react";
import { BuscarMuralFluxoEventos } from '../../../Services/FluxoEventos';
import MuralFluxoEventosItem from './MuralFluxoEventosItem';
import AppContext from '../../../Store/AppContext';
import "./MuralFluxoEventos.css";

const searchData = {
  dataInicial: '',
  dataFinal: ''
};

export function definirTipoSegmentacao(tipo, largura = "100%") {
  switch (tipo) {
    case 'SMS':
      return <span style={{ width: largura }} className="badge badge-sms">{tipo}</span>;
    case 'EMAIL':
      return <span style={{ width: largura }} className="badge badge-purple">{tipo}</span>;
    case 'WHATSAPP':
      return <span style={{ width: largura }} className="badge badge-whatsapp">{tipo}</span>;
    default:
      return null;
  }
};

function initialState() {
  return {
    searchData: searchData,
    visao: "inicial",
    segmentacao: {},
    muralFluxoEventos: {
      fluxoEventosId: 0,
      fluxoEventosNome: "",
      fluxoEventosDescricao: "",
      fluxoEventosAtivo: "",
      campanha: 0,
      enviada: 0,
      percentualEnviada: 0,
      visualizacoes: 0,
      percentualVisualizacoes: 0,
      erros: 0,
      percentualErros: 0,
      descadastros: 0,
      percentualDescadastros: 0,
      segmentacoes: []
    },
  };
}
export const hideModal = (target) => {
  $("#modalMuralFluxoEventos").modal('hide');
};

const MuralFluxoEventos = ({ children = null, fluxoEventosId = 0, CssClass = "" }) => {
  const { showLoading, hideLoading } = useContext(AppContext);
  const [pesquisaFluxoEventosValues, setMuralFluxoEventosValues] = useState(initialState());

  function showModal() {
    $(`#modalMuralFluxoEventos${fluxoEventosId}`).modal('show');
    if (fluxoEventosId > 0 && pesquisaFluxoEventosValues.muralFluxoEventos.fluxoEventosId !== fluxoEventosId) {
      BuscarMuralFluxoEventosHandler();
    }
  }

  function onChangeInput(event) {
    const { name, value } = event.target;

    setMuralFluxoEventosValues({
      ...pesquisaFluxoEventosValues,
      searchData: {
        ...pesquisaFluxoEventosValues.searchData,
        [name]: value
      }
    });
  }


  function setSegmentacao(segmentacao) {
    setMuralFluxoEventosValues({
      ...pesquisaFluxoEventosValues,
      visao: "template",
      segmentacao: segmentacao,
    });
  }

  function limparSegmentacao() {
    setMuralFluxoEventosValues({
      ...pesquisaFluxoEventosValues,
      visao: initialState().visao,
      segmentacao: initialState().segmentacao,
    });
  }

  async function BuscarMuralFluxoEventosHandler() {
    showLoading();
    console.log(pesquisaFluxoEventosValues.searchData)
    const responseBuscarMuralFluxoEventos = await BuscarMuralFluxoEventos({ ...pesquisaFluxoEventosValues.searchData, fluxoEventosId });

    hideLoading();
    if (!responseBuscarMuralFluxoEventos.success) {
      return;
    } else {
      if (responseBuscarMuralFluxoEventos.success) {
        let muralFluxoEventos = responseBuscarMuralFluxoEventos.muralFluxoEventos;
        setMuralFluxoEventosValues({
          ...pesquisaFluxoEventosValues,
          muralFluxoEventos
        });
      }
    }
  }

  function onSearchHandler() {
    BuscarMuralFluxoEventosHandler();
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-12 col-md-12">
          <span role="button"
            onClick={showModal}
          >
            {children == null ? <button
              className={CssClass ? CssClass : `btn btn-default`}
            >
              Mural Campanha
            </button> : children}
          </span>

        </div>
      </div>

      <div
        className="modal fade"
        id={`modalMuralFluxoEventos${fluxoEventosId}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`modalMuralFluxoEventos${fluxoEventosId}Label`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id={`modalMuralFluxoEventos${fluxoEventosId}Label`}
              >
                Mural Campanha
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body componenteMuralFluxoEventos_modalBody">
              {pesquisaFluxoEventosValues.visao === "inicial" ? <div>
                <div className="row">
                  <div className="col-12 col-md-2 form-group">
                    <label>
                      Data Inicial
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      name="dataInicial"
                      value={pesquisaFluxoEventosValues.searchData.dataInicial}
                      onChange={onChangeInput}
                    />
                  </div>
                  <div className="col-12 col-md-2 form-group">
                    <label>
                      Data Final
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      name="dataFinal"
                      value={pesquisaFluxoEventosValues.searchData.dataFinal}
                      onChange={onChangeInput}
                    />
                  </div>
                  <div className="col-12  col-md-2  mt-4">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={onSearchHandler}
                    >
                      Buscar
                    </button>
                  </div>
                </div>


                <hr />

                <div className="row">
                  <div className="col-12">
                    <div className="font-weight-bold text-left mt-3 mb-1">
                      Nome da campanha :
                      <span className="font-weight-normal ml-1">
                        {pesquisaFluxoEventosValues.muralFluxoEventos.fluxoEventosNome}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="font-weight-bold text-left mt-3 mb-1">
                      Descrição da campanha :
                      <span className="font-weight-normal ml-1">
                        {pesquisaFluxoEventosValues.muralFluxoEventos.fluxoEventosDescricao}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="font-weight-bold text-left mt-3 mb-1">
                      Status :
                      <span className="font-weight-normal ml-1">
                        {pesquisaFluxoEventosValues.muralFluxoEventos.fluxoEventosAtivo === "A" ? "Ativo" : "Inátivo"}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="font-weight-bold text-left mt-3 mb-1">
                      Segmentação :
                      <div className="row" >
                        {pesquisaFluxoEventosValues.muralFluxoEventos.segmentacoes.map((segmentacao) => (
                          <Fragment key={segmentacao.seguimentacaoId}>
                            <div className="col-1" />
                            <div className="col-11" >
                              {definirTipoSegmentacao(segmentacao.seguimentacaoTipo, "6%")} <span className="font-weight-normal ml-1 componenteMuralFluxoEventos_link" onClick={() => setSegmentacao(segmentacao)}>{segmentacao.seguimentacaoNome}</span>
                            </div>
                          </Fragment>
                        ))}
                      </div>

                    </div>
                  </div>
                  <div className="col-12">
                    <div className="font-weight-bold text-left mt-3 mb-1">
                      Campanha :
                      <span className="font-weight-normal ml-1">
                        {pesquisaFluxoEventosValues.muralFluxoEventos.campanha}
                      </span>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="row">
                  <div className="col-12">
                    <MuralFluxoEventosItem cor="success" valorDescricao='Enviados' valor={pesquisaFluxoEventosValues.muralFluxoEventos.enviada} percentual={pesquisaFluxoEventosValues.muralFluxoEventos.percentualEnviada} ></MuralFluxoEventosItem>
                  </div>

                </div>
                <div className="row">
                  <div className="col-12">
                    <MuralFluxoEventosItem cor="info" valorDescricao='Visualizações' valor={pesquisaFluxoEventosValues.muralFluxoEventos.visualizacoes} percentual={pesquisaFluxoEventosValues.muralFluxoEventos.percentualVisualizacoes} ></MuralFluxoEventosItem>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <MuralFluxoEventosItem cor="danger" valorDescricao='Erros' valor={pesquisaFluxoEventosValues.muralFluxoEventos.erros} percentual={pesquisaFluxoEventosValues.muralFluxoEventos.percentualErros} ></MuralFluxoEventosItem>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <MuralFluxoEventosItem cor="warning" valorDescricao='Descadastros' valor={pesquisaFluxoEventosValues.muralFluxoEventos.descadastros} percentual={pesquisaFluxoEventosValues.muralFluxoEventos.percentualDescadastros} ></MuralFluxoEventosItem>
                  </div>
                </div>
              </div> : null}
              {pesquisaFluxoEventosValues.visao === "template" ?
                <Fragment>
                  <div className="row">
                    <div className="col-12">
                      <div className="font-weight-bold text-left mt-3 mb-1">
                        Nome :
                        <span className="font-weight-normal ml-1">
                          {pesquisaFluxoEventosValues.segmentacao.seguimentacaoNome}
                        </span>
                      </div>
                      <div className="font-weight-bold text-left mt-3 mb-1">
                        Tipo :
                        <span className="font-weight-normal ml-1">
                          {pesquisaFluxoEventosValues.segmentacao.seguimentacaoTipo}
                        </span>
                      </div>
                      <div className="font-weight-bold text-left mt-3 mb-1">
                        Template :
                        {pesquisaFluxoEventosValues.segmentacao.seguimentacaoTipo === "SMS"
                          || pesquisaFluxoEventosValues.segmentacao.seguimentacaoTipo === "WATHSAPP" ?
                          <textarea
                            disabled={true}
                            cols="30"
                            rows="18"
                            className="form-control"
                            type="text"
                            name="observacao"
                            value={!pesquisaFluxoEventosValues.segmentacao.seguimentacaoTemplate ? ' ' : pesquisaFluxoEventosValues.segmentacao.seguimentacaoTemplate}
                            onChange={() => { }}
                          /> : null
                        }
                        {pesquisaFluxoEventosValues.segmentacao.seguimentacaoTipo === "EMAIL" ?
                          <div dangerouslySetInnerHTML={{ __html: pesquisaFluxoEventosValues.segmentacao.seguimentacaoTemplate ? pesquisaFluxoEventosValues.segmentacao.seguimentacaoTemplate : "" }} /> : null
                        }
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => limparSegmentacao()}
                    >
                      Voltar
                    </button>
                  </div>
                </Fragment>
                : null}

            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MuralFluxoEventos;
