import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Auto";

export const ObterInformacoesVeiculoPorPlaca = (placa) =>
  RequestWebApi.get(`/${controller}/ObterInformacoesVeiculoPorPlaca`, {
    params: { placa },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const informacoesVeiculoPorPlaca = resultData;
      return { success, informacoesVeiculoPorPlaca };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, informacoesVeiculoPorPlaca: null };
    });


export const ObterFabricantesFipe = (tipoVeiculo) =>
  RequestWebApi.get(`/${controller}/ObterFabricantesFipe`, {
    params: { tipoVeiculo },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const fabricantesFipe = resultData;
      return { success, fabricantesFipe };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { fabricantesFipe: [] };
    });


export const ObterModelosFipeDoFabricantePorAno = (tipoVeiculo, codigoFabricante, anoModelo) =>
  RequestWebApi.get(`/${controller}/ObterModelosFipeDoFabricantePorAno`, {
    params: { tipoVeiculo, codigoFabricante, anoModelo },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const modelosFipe = resultData;
      return { success, modelosFipe };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { modelosFipe: [] };
    });

export const ObterDadosModeloVeiculoFipe = (tipoVeiculo, codigoFabricante, anoModelo, codigoModelo) =>
  RequestWebApi.get(`/${controller}/ObterDadosModeloVeiculoFipe`, {
    params: { tipoVeiculo, codigoFabricante, anoModelo, codigoModelo },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const detalhesModeloFipe = resultData;
      return { success, detalhesModeloFipe };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, detalhesModeloFipe: null };
    });

