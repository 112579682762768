import React, { Fragment, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Agendamento from "../../../../../Components/Agenda/Agendamento/Agendamento";
import DataTableDefault from "../../../../../Components/DataTableDefault/DataTableDefault";
import HistoricoLead from "../../../../../Components/Lead/HistoricoLead/HistoricoLead";
import Pesquisar from "../../../../../Components/Pesquisar/Pesquisar";
import { retornaDescricaoTipoAtendimento } from "../../../../../Utils/globalFunctions";
import AlterarFilaModal from "../Component/AlterarFila/AlterarFilaModal";
import { FilaAtendimentoContext } from "../FilaAtendimentoContext";
import ModalGenerico from "../../../../../Components/Modal/ModalGenerico/ModalGenerico";

const PesquisarFilaAtendimento = () => {
  let history = useHistory();
  const {
    distribuirLeadsValues,
    setDistribuirLeadsValues,
    resetScreen,
    searchHandler,
    modificarFilaHandler,
  } = useContext(FilaAtendimentoContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      searchData: {
        ...distribuirLeadsValues.searchData,
        [name]: value,
      },
    });
  }

  const statusTabela = (cell, row) => {
    let classe = "info";
    if (row.atendimentoTipo === "C") {
      classe = "warning";
    } else if (row.atendimentoTipo === "N") {
      classe = "success";
    }
    return (
      <div className="d-flex ">
        {row.leadIdRenovacao && row.leadIdRenovacao > 0 ? (
          <span
            data-toggle="tooltip"
            data-placement="top"
            title="Renovação"
            className={`badge-default rounded-pill pl-1 pr-1 mr-1`}
          >
            R
          </span>
        ) : (
          <span
            data-toggle="tooltip"
            data-placement="top"
            title="Novo Lead"
            className={`badge-info rounded-pill pl-1 pr-1 mr-1`}
          >
            N
          </span>
        )}

        {
          (row.filaAtendimentoDataReprovaVenda && row.atendimentoTipo === "N") ?
            <ModalGenerico
              buttonToShow={<span className={`badge badge-danger pt-2`}>
                Não Aprovado
              </span>}
            >
              <div className="col-12" >
                <div class="alert alert-danger mt-2" role="alert">
                  <h4 class="alert-heading">Não Aprovado</h4>
                  <hr />
                  <p class="text-left mb-0">Motivo:</p>
                  <p class="text-justify mb-0">{row.filaAtendimentoMotivoReprovaVenda}</p>
                </div>
              </div>

            </ModalGenerico>
            :
            <span className={`badge badge-${classe} pt-1`}>
              {retornaDescricaoTipoAtendimento(row.atendimentoTipo)}
            </span>

        }


      </div>
    );
  };

  const acoesTabela = (cell, row) => {
    let classe = "success";
    let texto = "Iniciar Atendimento";
    let evento = () => {
      history.push(`/Atendimento/Atendimento/${row.filaAtendimentoId}`);
    };
    if (row.atendimentoTipo === "C" || row.atendimentoTipo === "N") {
      const filaAtendimento = distribuirLeadsValues.resultSearch.find(
        (fila) => fila.filaAtendimentoId === row.filaAtendimentoId
      );

      texto = "Verificar Agenda";
      return (
        <Agendamento
          filaAtendimentoId={filaAtendimento.filaAtendimentoId}
          refreshEventHandler={searchHandler}
          tiposAgendamento={distribuirLeadsValues.tiposAgendamento}
          agendamentoId={filaAtendimento.agendamentoId}
          tipoAgendamentoId={filaAtendimento.tipoAgendamentoId}
          tipoAgendamentoDescricao={filaAtendimento.tipoAgendamentoDescricao}
          leadNome={filaAtendimento.leadNome}
          cidadeLead={filaAtendimento.cidadeNome}
          totalContatos={filaAtendimento.numeroContatos}
          dataRecebimentoLead={filaAtendimento.dataRecebimentoLead}
          horaRecebimentoLead={filaAtendimento.horaRecebimentoLead}
          dataAgendamento={filaAtendimento.dataAgendamento}
          horaAgendamento={filaAtendimento.horaAgendamento}
          diaSemanaAgendamento={filaAtendimento.diaSemanaAgendamento}
          atendimentos={filaAtendimento.historicoAtendimento}
        >
          <span role="button" className="badge btn-default">
            {texto}
          </span>
        </Agendamento>
      );
    }
    return (
      <span role="button" className={`badge btn-${classe}`} onClick={evento}>
        {texto}
      </span>
    );
  };

  const columnsDefinition = [
    {
      dataField: "alterarfila",
      text: "Ações",
      headerStyle: { width: "50px" },
      isDummyField: true,
      csvExport: false,
      headerAttrs: { width: "50px" },

      formatter: (cell, row) => {
        return row.podeAlterarFila === true ? (
          <div style={{ textAlign: "center" }}>
            <AlterarFilaModal
              consultores={distribuirLeadsValues.consultoresOnline}
              produtos={distribuirLeadsValues.produtos}
              id={row.filaAtendimentoId}
              usuarioId={row.usuarioId}
              produtoId={row.produtoId}
              modificarFilaHandler={modificarFilaHandler}
            />
          </div>
        ) : null;
      },
    },
    {
      text: "filaAtendimentoId",
      dataField: "filaAtendimentoId",
      hidden: true,
    },
    {
      text: "usuarioId",
      dataField: "usuarioId",
      hidden: true,
    },
    {
      text: "produtoId",
      dataField: "produtoId",
      hidden: true,
    },
    {
      text: "leadId",
      dataField: "leadId",
      headerStyle: { width: "90px" },
      hidden: true,
    },
    {
      text: "podeAlterarFila",
      dataField: "podeAlterarFila",
      hidden: true,
    },
    {
      text: "Status",
      dataField: "atendimentoTipo",
      headerStyle: { width: "90px" },
      sort: true,
      formatter: statusTabela,
    },
    {
      text: "Nome",
      dataField: "leadNome",
      headerStyle: { width: "175px" },
      sort: true,
      formatter: (cell, row) => {
        return (
          <HistoricoLead leadId={row.leadId} disabled={false} >
            <span className="link_primary">
              {cell}
            </span>
          </HistoricoLead>
        );
      },
    },
    {
      text: "Scoring",
      dataField: "leadScoringSystem",
      headerStyle: { width: "55px" },
    },
    {
      text: "Nrs.Cont.",
      dataField: "numeroContatos",
      headerStyle: { width: "70px" },
      sort: false,
    },
    {
      text: "Loja Virtual",
      dataField: "lojaVirtualNome",
      headerStyle: { width: "150px" },
      sort: true,
    },
    {
      text: "Origem",
      dataField: "origemLeadNome",
      headerStyle: { width: "100px" },
      sort: true,
    },
    {
      text: "Produto",
      dataField: "produtoNome",
      headerStyle: { width: "100px" },
      sort: true,
    },
    {
      text: "Venc. Apól",
      dataField: "leadDataVencApolice",
      headerStyle: { width: "100px" },
      sort: true,
    },
    {
      text: "Consultor",
      dataField: "usuarioNome",
      headerStyle: { width: "150px" },
      sort: true,
    },
    {
      text: "Dt/Hra Retorno",
      dataField: "dataHoraRetorno",
      headerStyle: { width: "110px" },
      sort: false,
    },
    {
      text: "Espera",
      dataField: "tempoEspera",
      headerStyle: { width: "90px" },
      sort: true,
    },
    {
      text: "",
      dataField: "acoes",
      headerStyle: { width: "110px" },
      isDummyField: true,
      formatter: acoesTabela,
    },
  ];

  return (
    <Fragment>
      <Pesquisar context={FilaAtendimentoContext}>
        {distribuirLeadsValues.consultoresOnline.length > 0 ? (
          <div className="col-12 col-md-3">
            <label>Consultor</label>
            <select
              className="form-control"
              name="usuarioId"
              value={distribuirLeadsValues.searchData.usuarioId}
              onChange={onChangeInput}
            >
              <option value="0">TODOS CONSULTORES</option>
              {distribuirLeadsValues.consultoresOnline
                ? distribuirLeadsValues.consultoresOnline.map((consultor) => (
                  <option key={consultor.value} value={consultor.value}>
                    {consultor.label}
                  </option>
                ))
                : null}
            </select>
          </div>
        ) : null}

        <div className="col-12 col-md-12" />
        <div className="col-12 col-md-2 pt-2">
          <label>Venc. Apólice</label>
          <input
            className="form-control"
            type="date"
            name="leadDataVencApolice"
            value={distribuirLeadsValues.searchData.leadDataVencApolice}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3">
          <label>Produto</label>
          <select
            className="form-control"
            name="produtoId"
            value={distribuirLeadsValues.searchData.produtoId}
            onChange={onChangeInput}
          >
            <option value="0">TODOS</option>
            {distribuirLeadsValues.produtos
              ? distribuirLeadsValues.produtos.map((produto) => (
                <option key={produto.produtoId} value={produto.produtoId}>
                  {produto.produtoNome}
                </option>
              ))
              : null}
          </select>
        </div>
        <div className="col-12 col-md-3">
          <label>Origem</label>
          <select
            className="form-control"
            name="origemLeadId"
            value={distribuirLeadsValues.searchData.origemLeadId}
            onChange={onChangeInput}
          >
            <option value="0">TODAS</option>
            {distribuirLeadsValues.origensLead
              ? distribuirLeadsValues.origensLead.map((origemLead) => (
                <option key={origemLead.origemLeadId} value={origemLead.origemLeadId}>
                  {origemLead.origemLeadNome}
                </option>
              ))
              : null}
          </select>
        </div>
        <div className="col-12 col-md-3">
          <label>Status</label>
          <select
            className="form-control"
            name="atendimentoTipo"
            value={distribuirLeadsValues.searchData.atendimentoTipo}
            onChange={onChangeInput}
          >
            <option value="">TODOS</option>
            <option value="NV">Novo</option>
            <option value="C">Contato</option>
            <option value="N">Negociação</option>

          </select>
        </div>
        <div className="col-12 col-md-12" />
        <div className="col-12 col-md-4 pt-2">
          <label>Nome</label>
          <input
            className="form-control"
            type="text"
            name="leadNome"
            value={distribuirLeadsValues.searchData.leadNome}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-1">
          <label>DDD</label>
          <input
            className="form-control"
            type="text"
            name="leadDddTelefone"
            value={distribuirLeadsValues.searchData.leadDddTelefone}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-2">
          <label>Telefone</label>
          <input
            className="form-control"
            type="text"
            name="leadNumTelefone"
            value={distribuirLeadsValues.searchData.leadNumTelefone}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3">
          <label>Email</label>
          <input
            className="form-control"
            type="text"
            name="leadEmail"
            value={distribuirLeadsValues.searchData.leadEmail}
            onChange={onChangeInput}
          />
        </div>
      </Pesquisar>
      <div className="row">
        <div className="col-12">
          <DataTableDefault
            keyField="filaAtendimentoId"
            data={distribuirLeadsValues.resultSearch}
            columns={columnsDefinition}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default PesquisarFilaAtendimento;
