/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Anexos from "../../../../../Components/Anexos/Anexos";
import FormularioLeadAuto from "../../../../../Components/BackOffice/FormulariosEspecificosProduto/FormularioLeadAuto";
import FormularioVendaLeadAuto from "../../../../../Components/BackOffice/FormulariosEspecificosProduto/FormularioVendaLeadAuto";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";
import FormularioComplementar from "../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import HistoricoLead from "../../../../../Components/Lead/HistoricoLead/HistoricoLead";
import { validarCpf, validarCnpj } from "../../../../../Utils/globalFunctions";
import { BackOfficeAtendimentoContext } from "../BackOfficeAtendimentoContext";
import IndicadoPor from "../../../../../Components/Lead/IndicadoPor/IndicadoPor"
import { formatarMoeda, parseNumber } from "../../../../../Utils/globalFunctions";

var controleExibicao = {
  apoliceVigenciaDataIni: true,
  apoliceVigenciaDataFim: true,
  numeroApolice: true,
  dataPendencia: true,
  pendencia: true,
  observacao: true,
  tipoTransmissao: true,
  dataRecusa: true,
  motivoRecusa: true,
};
const definirVisibilidade = (value) => {
  switch (value) {
    case "E":
      controleExibicao = {
        ...controleExibicao,
        apoliceVigenciaDataIni: true,
        apoliceVigenciaDataFim: true,
        dataApolice: true,
        numeroApolice: true,
        dataPendencia: true,
        dataLimitePendencia: true,
        pendencia: true,
        dataRecusa: false,
        motivoRecusa: false,
      };
      break;
    case "P":
      controleExibicao = {
        ...controleExibicao,
        apoliceVigenciaDataIni: false,
        apoliceVigenciaDataFim: false,
        dataApolice: false,
        numeroApolice: false,
        dataPendencia: true,
        dataLimitePendencia: true,
        pendencia: true,
        dataRecusa: false,
        motivoRecusa: false,
      };
      break;
    case "R":
      controleExibicao = {
        ...controleExibicao,
        apoliceVigenciaDataIni: false,
        apoliceVigenciaDataFim: false,
        dataApolice: false,
        numeroApolice: false,
        dataPendencia: false,
        dataLimitePendencia: false,
        pendencia: false,
        observacao: true,
        tipoTransmissao: false,
        dataRecusa: true,
        motivoRecusa: true,
      };
      break;
    default:
      break;
  }
};
const FormularioBackOfficeAtendimento = () => {
  const { values, setValues, getDetailHandler, showMessage, obterBonusCartaoPortoNovo } = useContext(
    BackOfficeAtendimentoContext
  );

  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id, definirVisibilidade);
  }, [id]);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  function onChangeInputPremioLiquido(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        utilizaCataoPortoNovo: false,
        valorBonusCartaoPortoNovo: 0,
        [name]: value,
      },
    });
  }

  function onChangeInputCpfCnpj(event) {
    const { name, value } = event.target;
    var numeroDocumento = value;
    if (numeroDocumento == null) numeroDocumento = '';

    numeroDocumento = numeroDocumento.replace(/[/.-]/g, '');
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: numeroDocumento,
      },
    });
  }

  function onChangeCheck(event) {
    const { name, checked } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: checked,
      },
    });
  }

  function validarDocumentoDigitado(event) {

    const { value } = event.target;
    var numeroDocumento = value;
    if (numeroDocumento == null) numeroDocumento = '';

    numeroDocumento = numeroDocumento.replace(/[.-]/g, '');

    if (numeroDocumento.length !== 11 && numeroDocumento.length !== 14) {
      showMessage('erro', 'Documento digitado esta no formato inválido.');
      setValues({
        ...values,
        data: {
          ...values.data,
          leadNumDocumento: "",
        },
      });
      return;
    }

    if (numeroDocumento.length === 11) {
      if (!validarCpf(numeroDocumento)) {
        showMessage('erro', 'Cpf Inválido.');
        setValues({
          ...values,
          data: {
            ...values.data,
            leadNumDocumento: "",
          },
        });
        return;
      }
    }
    if (numeroDocumento.length === 14) {
      if (!validarCnpj(numeroDocumento)) {
        showMessage('erro', 'Cnpj Inválido.');
        setValues({
          ...values,
          data: {
            ...values.data,
            leadNumDocumento: "",
          },
        });
        return;
      }
    }
  }

  function onChangeStatusEmissao(event) {
    onChangeInput(event);
    const { value } = event.target;
    definirVisibilidade(value);
  }



  async function onChangeCheckInputUtilizaCataoPortoNovo(event) {
    const { checked } = event.target;

    let valorBonusCartaoPortoNovo = 0;

    if (checked) {
      valorBonusCartaoPortoNovo = await obterBonusCartaoPortoNovo();
    }

    setValues({
      ...values,
      data: {
        ...values.data,
        utilizaCataoPortoNovo: checked,
        valorBonusCartaoPortoNovo
      },
    });

  }



  const valorPremioLiquido = formatarMoeda((
    parseNumber(values?.data?.valorLiquido) +
    parseNumber(values?.data?.valorBonusCartaoPortoNovo)
  ));

  return (
    <Fragment>
      <Formulario context={BackOfficeAtendimentoContext}>
        {values.data.lojaVirtualNome || values.data.lojaVirtualImagem ?
          <div className="col-12 col-md-12 form-group text-left">
            <IndicadoPor nome={values.data.lojaVirtualNome} imagem={values.data.lojaVirtualImagem} />
            <hr />
          </div> : null
        }
        <div className="col-12 col-md-3 form-group text-left">
          {values.data.leadIdRenovacao && values.data.leadIdRenovacao > 0 ? (
            <span
              data-toggle="tooltip"
              data-placement="top"
              title="Renovação"
              className={`badge-default rounded-pill pl-1 pr-1 mr-1`}
            >
              Renovação
            </span>
          ) : (
            <span
              data-toggle="tooltip"
              data-placement="top"
              title="Novo Lead"
              className={`badge-info rounded-pill pl-1 pr-1 mr-1`}
            >
              Novo Lead
            </span>
          )}
        </div>
        <div className="col-12 col-md-3 form-group text-left">
          {values.data.possuiSinistro ? <span
            data-toggle="tooltip"
            data-placement="top"
            title="Renovação"
            className={`badge-danger rounded-pill pl-1 pr-1 mr-1`}
          >
            Possui Sinistro
          </span> : null}

        </div>
        <div className="col-12 col-md-3 form-group ">
          {values.data.leadId > 0 ?
            <HistoricoLead leadId={values.data.leadId} disabled={false} >
              <span className="link_primary">
                Histórico do Lead
              </span>
            </HistoricoLead> : null
          }

        </div>
        <div className="col-12 col-md-3 form-group ">
          {values.data.leadId > 0 ?
            <HistoricoLead leadId={values.data.leadId} disabled={false} titulo="Histórico de Cobranças" visaoExclusiva="Cobrancas">
              <span className="link_primary">
                Visualizar Cobranças
              </span>
            </HistoricoLead> : null
          }
        </div>

        <div className="col-12 col-md-12" />
        <div className="col-12 col-md-5 form-group">
          <label>
            Nome<span className="campoObratorio">*</span>
          </label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            maxLength="150"
            name="leadNome"
            value={values.data.leadNome}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-5 form-group">
          <label>
            Email
          </label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            maxLength="150"
            name="leadEmail"
            value={values.data.leadEmail}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>CPF/CNPJ</label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            name="leadNumDocumento"
            value={values.data.leadNumDocumento}
            onChange={onChangeInputCpfCnpj}
            onBlur={validarDocumentoDigitado}
          />
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>
            Produto <span className="campoObratorio">*</span>
          </label>
          <select
            disabled={values.data.vendaId > 0 || values.formDisabled}
            className="form-control"
            name="produtoId"
            value={values.data.produtoId}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0" />
            {values.produtos.map((produto) => (
              <option key={produto.produtoId} value={produto.produtoId}>
                {produto.produtoNome}
              </option>
            ))}
          </select>
        </div>
        {values.data.cadastroInterno ? null : (
          <div className="col-12 col-md-3 form-group">
            <label>Seguradora</label>
            <select
              disabled={values.formDisabled}
              className="form-control"
              name="seguradoraId"
              value={values.data.seguradoraId}
              onChange={onChangeInput}
            >
              <option className="disabled" value="0" />
              {values.seguradoras.map((seguradora) => (
                <option
                  key={seguradora.seguradoraId}
                  value={seguradora.seguradoraId}
                >
                  {seguradora.seguradoraDescricao}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="col-12 col-md-12 form-group" />
        <div className="col-12 col-md-3 form-group">
          <label>
            Origem Lead
          </label>
          <input
            disabled={true}
            className="form-control"
            type="text"
            maxLength="50"
            name="origemLeadNome"
            value={values.data.origemLeadNome}
          />
        </div>
        {values.data.cadastroInterno ? (
          <div className="col-12 col-md-3 form-group">
            <label>
              Consultor <span className="campoObratorio">*</span>
            </label>
            <select
              disabled={values.formDisabled}
              className="form-control"
              name="hunterId"
              value={values.data.hunterId}
              onChange={onChangeInput}
            >
              <option className="disabled" value="0" />
              {values.hunters.map((hunter) => (
                <option key={hunter.hunterId} value={hunter.hunterId}>
                  {hunter.hunterDescricao}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="col-12 col-md-5 form-group">
            <label>Consultor</label>
            <input
              disabled
              className="form-control"
              type="text"
              name="consultor"
              value={values.data.consultorNome}
              onChange={onChangeInput}
            />
          </div>
        )}
        <div className="col-12 col-md-2 form-group">
          <label>Data da Proposta</label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="date"
            name="dataProposta"
            value={values.data.dataProposta}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>Num. da Proposta</label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            maxLength="50"
            name="numeroProposta"
            value={values.data.numeroProposta}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-12 form-group" />
        <div className="col-12 col-md-3 form-group">
          <label>Status da Emissão</label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="statusEmissao"
            value={values.data.statusEmissao}
            onChange={onChangeStatusEmissao}
          >
            {values.statusEmissaoList.map((status) => (
              <option key={status.id} value={status.id}>
                {status.descricao}
              </option>
            ))}
          </select>
        </div>
        {controleExibicao.dataRecusa ? (
          <div className="col-12 col-md-2 form-group">
            <label>
              Data da Recusa<span className="campoObratorio">*</span>
            </label>
            <input
              disabled={values.formDisabled}
              className="form-control"
              type="date"
              name="dataRecusa"
              value={values.data.dataRecusa}
              onChange={onChangeInput}
            />
          </div>
        ) : null}
        {controleExibicao.motivoRecusa ? (
          <div className="col-12 col-md-3 form-group">
            <label>
              {" "}
              Motivo da Recusa
              <span className="campoObratorio">*</span>
            </label>
            <select
              disabled={values.formDisabled}
              className="form-control"
              name="motivoRecusa"
              value={values.data.motivoRecusa}
              onChange={onChangeInput}
            >
              <option className="disabled" value="" />
              {values.motivosRecusa.map((motivoRecusa) => (
                <option key={motivoRecusa.codigo} value={motivoRecusa.codigo}>
                  {motivoRecusa.descricao}
                </option>
              ))}
            </select>
          </div>
        ) : null}

        {controleExibicao.numeroApolice ? (
          <div className="col-12 col-md-3 form-group">
            <label>
              Num. da Apólice
              <span className="campoObratorio">*</span>
            </label>
            <input
              disabled={values.formDisabled}
              className="form-control"
              type="text"
              maxLength="50"
              name="numeroApolice"
              value={values.data.numeroApolice}
              onChange={onChangeInput}
            />
          </div>
        ) : null}

        {controleExibicao.apoliceVigenciaDataIni ? (
          <div className="col-12 col-md-3 form-group">
            <label>
              Início Vigência<span className="campoObratorio">*</span>
            </label>
            <input
              disabled={values.formDisabled}
              className="form-control"
              type="date"
              name="apoliceVigenciaDataIni"
              value={values.data.apoliceVigenciaDataIni}
              onChange={onChangeInput}
            />
          </div>
        ) : null}

        {controleExibicao.apoliceVigenciaDataFim ? (
          <div className="col-12 col-md-3 form-group">
            <label>
              Fim Vigência<span className="campoObratorio">*</span>
            </label>
            <input
              disabled={values.formDisabled}
              className="form-control"
              type="date"
              name="apoliceVigenciaDataFim"
              value={values.data.apoliceVigenciaDataFim}
              onChange={onChangeInput}
            />
          </div>
        ) : null}


        {controleExibicao.dataPendencia ? (
          <div className="col-12 col-md-2 form-group">
            <label>Data da Pendência</label>
            <input
              disabled={values.formDisabled}
              className="form-control"
              type="date"
              name="dataPendencia"
              value={values.data.dataPendencia}
              onChange={onChangeInput}
            />
          </div>
        ) : null}

        {controleExibicao.pendencia ? (
          <div className="col-12 col-md-3 form-group">
            <label> Pendência</label>
            <select
              disabled={values.formDisabled}
              className="form-control"
              name="pendencia"
              value={values.data.pendencia}
              onChange={onChangeInput}
            >
              <option className="disabled" value="" />
              {values.tiposPendencia.map((tipoPendencia) => (
                <option key={tipoPendencia.codigo} value={tipoPendencia.codigo}>
                  {tipoPendencia.descricao}
                </option>
              ))}
            </select>
          </div>
        ) : null}

        {controleExibicao.dataLimitePendencia ? (
          <div className="col-12 col-md-2 form-group">
            <label>Limite Pendência</label>
            <input
              disabled={values.formDisabled}
              className="form-control"
              type="date"
              name="dataLimitePendencia"
              value={values.data.dataLimitePendencia}
              onChange={onChangeInput}
            />
          </div>
        ) : null}

        <div className="col-12" />

        <div className="col-12 col-md-2">
          <div className="form-group">
            <label>Prêmio Liq.</label>
            <input
              disabled={values.formDisabled}
              type="text"
              className="form-control monetario"
              name="valorLiquido"
              value={values.data.valorLiquido}
              onChange={onChangeInputPremioLiquido}
              onBlur={onChangeInputPremioLiquido}
            />
          </div>
        </div>

        <div className="col-12 col-md-2 form-group pt-4">
          <div className="custom-control custom-switch float-right">
            <input
              disabled={values.formDisabled}
              checked={values.data.utilizaCataoPortoNovo}
              type="checkbox"
              className="custom-control-input"
              id="ckUtilizaCataoPortoNovo"
              onChange={onChangeCheckInputUtilizaCataoPortoNovo}
            />
            <label className="custom-control-label" htmlFor="ckUtilizaCataoPortoNovo">
              Cartão Porto Novo?
            </label>
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label>Premio Liq. Atualizado</label>
            <input
              disabled={true}
              type="text"
              className="form-control formularioNegociacao-totalComissao"
              name="valorComissao"
              value={valorPremioLiquido}
            />
          </div>
        </div>

        <div className="col-12 col-md-2">
          <div className="form-group">
            <label>% Comissão</label>
            <input
              disabled={values.formDisabled}
              type="text"
              className="form-control porcentagem3Digitos"
              name="percentualComissao"
              value={values.data.percentualComissao}
              onChange={onChangeInput}
            />
          </div>
        </div>
        <div className="col-12" />
        <div className="col-12 col-md-2">
          <div className="form-group">
            <label>Tipo Veículo</label>
            <select
              disabled={values.formDisabled}
              className="form-control"
              name="tipoVeiculoId"
              value={values.data.tipoVeiculoId}
              onChange={onChangeInput}
            >
              <option className="disabled" value="0" />
              {values.tiposVeiculo.map((tipoVeiculo) => (
                <option
                  key={tipoVeiculo.tipoVeiculoId}
                  value={tipoVeiculo.tipoVeiculoId}
                >
                  {tipoVeiculo.tipoVeiculoDescricao}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group">
            <label>Tipo Negócio</label>
            <select
              disabled={values.formDisabled}
              className="form-control"
              name="leadTipoNegocio"
              value={values.data.leadTipoNegocio}
              onChange={onChangeInput}
            >
              <option className="disabled" value="" />
              {values.tiposNegocio.map((tipoNegocio) => (
                <option key={tipoNegocio.codigo} value={tipoNegocio.codigo}>
                  {tipoNegocio.descricao}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-12 form-group">
          <label>Observação</label>
          <textarea
            cols="30"
            rows="4"
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            name="observacao"
            value={!values.data.observacao ? ' ' : values.data.observacao}
            onChange={onChangeInput}
          />
        </div>

        {values.data.observacaoHistorico ? (
          <div className="col-12 col-md-12 form-group">
            <label>Histórico</label>
            <textarea
              cols="30"
              rows="6"
              disabled={true}
              className="form-control"
              type="text"
              name="observacaoHistorico"
              value={values.data.observacaoHistorico}
              onChange={onChangeInput}
            />
          </div>
        ) : null}

        <div className="col-12 col-md-2 ">
          <label>Erro Operador?</label>
          <div className="col-12 col-md-2 pd-3 pl-5">
            <div className="custom-control custom-switch float-center">
              <input
                disabled={values.formDisabled}
                checked={values.data.erroOperador}
                type="checkbox"
                className="custom-control-input"
                id="erroOperador"
                name="erroOperador"
                onChange={onChangeCheck}
              />
              <label className="custom-control-label" htmlFor="erroOperador" />
            </div>
          </div>
        </div>

        <div className="col-12 col-md-2 ">
          <label>Status do Cadastro?</label>
          <div className="col-12 col-md-2 pd-3 pl-5">
            <div className="custom-control custom-switch float-center">
              <input
                disabled={values.formDisabled}
                checked={values.data.statusCadastro}
                type="checkbox"
                className="custom-control-input"
                id="statusCadastro"
                name="statusCadastro"
                onChange={onChangeCheck}
              />
              <label
                className="custom-control-label"
                htmlFor="statusCadastro"
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-md-2 form-group">
          <label>
            Mês/Ano Produção
          </label>
          <input
            disabled={true}
            className="form-control"
            type="text"
            name="leadMesAnoProducao"
            value={values.data.leadMesAnoProducao}
            onChange={onChangeInput}
          />
        </div>

        <div className="col-12 col-md-2 form-group">
          <label>
            Data do Cadastro
            <span className="campoObratorio">
              {values.data.statusCadastro ? "*" : ""}
            </span>
          </label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="date"
            name="dataCadastro"
            value={values.data.dataCadastro}
            onChange={onChangeInput}
          />
        </div>



        {controleExibicao.tipoTransmissao ? (
          <div className="col-12 col-md-3 form-group">
            <label>Tipo de Transmissão</label>
            <select
              disabled={values.formDisabled}
              className="form-control"
              name="tipoTransmissao"
              value={values.data.tipoTransmissao}
              onChange={onChangeInput}
            >
              <option className="disabled" value="" />
              {values.tiposTransmissao.map((tipoTransmissao) => (
                <option
                  key={tipoTransmissao.codigo}
                  value={tipoTransmissao.codigo}
                >
                  {tipoTransmissao.descricao}
                </option>
              ))}
            </select>
          </div>
        ) : null}

        <div className="col-12 col-md-4 form-group">
          <label>Anexos</label>
          <Anexos
            id="formularioBackOfficeAtendimento"
            context={BackOfficeAtendimentoContext}
            disabled={values.formDisabled}
          />
        </div>
      </Formulario>

      {values.produto.produtoFormAuxLead ||
        values.produto.produtoFormAuxVenda ? (
        <FormularioComplementar titulo="Dados Complementares">
          <FormularioLeadAuto
            produto={values.produto}
            leadAuto={values.data.leadAuto}
          />
          <FormularioVendaLeadAuto
            produto={values.produto}
            vendaAuto={values.data.vendaAuto}
          />
        </FormularioComplementar>
      ) : null}
    </Fragment>
  );
};

export default FormularioBackOfficeAtendimento;
