import React, { Fragment } from "react";
import "./ModalGenerico.css";

const ModalGenerico = ({ buttonToShow = null, children, id = "", titulo = "", value = "" }) => {
  return (
    <Fragment>
      <div
        className="componenteModalGenerico_container"
        role="button"
        data-toggle="modal"
        data-target={`#modalModalModalGenerico${id}`}
      >
        {buttonToShow ?? (<div style={{ width: "100%", textAlign: "center" }}>
          <button
            type="button"
            className="btn btn-default btn-sm ">
            Abrir
          </button>
        </div>)}
      </div>

      <div
        className="modal fade"
        id={`modalModalModalGenerico${id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`modalModalModalGenerico${id}Label`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id={`modalModalModalGenerico${id}Label`}
              >
                {titulo}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body componenteModalGenerico_modalBody">
              <div className="row">
                {children}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ModalGenerico;
