import React, { Fragment, useContext } from "react";
import "./AtendimentoAprovacao.css";
import { AprovacaoVendasAtendimentoContext } from "../AprovacaoVendasAtendimentoContext";

const AtendimentoAprovacao = ({ antendimentoParaAprovar = null }) => {
  const { onChangeInputPadraoGrid } = useContext(AprovacaoVendasAtendimentoContext);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card shadow-sm mb-4">
            <div className="row mt-2 pl-3" >

              <div className="col-3">
                <p>
                  <b>Data Venda:</b>  {antendimentoParaAprovar.dataVenda}
                </p>
              </div>

              <div className="col-3">
                <b >Produto:</b>  {antendimentoParaAprovar.tipoProduto}
              </div>

              <div className="col-6">
                <b>Nome Lead:</b>  {antendimentoParaAprovar.leadNome}
              </div>

              <div className="col-12">
                <p>
                  <b >Observação Atendimento:</b>  {antendimentoParaAprovar.atendimentoObservacao}
                </p>
              </div>
              <div className="col-12">
                <hr />
              </div>
              <div className="col-2">
                <label>
                  Aprovado?
                </label>
                <div className="col-12">
                  <div className="row" >
                    <div className="col-6 d-flex">
                      <div className="mt-2"><label>Sim</label></div>   <input
                        className="defafult-radio"
                        style={{ marginLeft: "30px" }}
                        type="radio"
                        id={"vendaReprovadaS_" + antendimentoParaAprovar.filaAtendimentoId}
                        name={"vendaReprovada_" + antendimentoParaAprovar.filaAtendimentoId}
                        value="N"
                        checked={
                          antendimentoParaAprovar.vendaReprovada === "N"
                        }
                        onChange={onChangeInputPadraoGrid}
                      />
                    </div>

                    <div className="col-6 d-flex">
                      <div className="mt-2"><label>Não</label></div> <input
                        className="defafult-radio"
                        style={{ marginLeft: "30px" }}
                        type="radio"
                        id={"vendaReprovadaN_" + antendimentoParaAprovar.filaAtendimentoId}
                        name={"vendaReprovada_" + antendimentoParaAprovar.filaAtendimentoId}
                        value="S"
                        checked={
                          antendimentoParaAprovar.vendaReprovada === "S"
                        }
                        onChange={onChangeInputPadraoGrid}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-10">
                <label>
                  Motivo Recusa
                </label>
                <input
                  disabled={!antendimentoParaAprovar.vendaReprovada || antendimentoParaAprovar.vendaReprovada === "N"}
                  className="form-control"
                  type="text"
                  id={"motivoReprovaVenda_" + antendimentoParaAprovar.filaAtendimentoId}
                  name="motivoReprovaVenda"
                  value={antendimentoParaAprovar.motivoReprovaVenda}
                  onChange={onChangeInputPadraoGrid}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AtendimentoAprovacao;
