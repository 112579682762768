/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import HistoricoLead from "../../../../Components/Lead/HistoricoLead/HistoricoLead";
import {
  Detalhes as DetalhesBackOfficeAtendimento, Listar as ListarBackOfficeAtendimento, ProcessarBackOfficeAtendimento
} from "../../../../Services/BackOfficeAtendimento";
import { DownloadArquivo } from "../../../../Services/Download";
import { Listar as ListarHunter } from "../../../../Services/Hunter";
import {
  IncluirLeadInterno, ListarArquivosLead, RemoverAnexosLead, UploadAnexosLead
} from "../../../../Services/Lead";
import { ListarListBoxPorRotulo } from "../../../../Services/ListBox";
import {
  Detalhes as DetalhesProduto, Listar as ListarProduto
} from "../../../../Services/Produto";
import { Listar as ListarSeguradora } from "../../../../Services/Seguradora";
import { Listar as ListarTipoVeiculo } from "../../../../Services/TipoVeiculo";
import { ListarSimples as ListarUsuario } from "../../../../Services/Usuario";
import AppContext from "../../../../Store/AppContext";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
import { Listar as ListarCatalogo } from '../../../../Services/Catalogo';

export const BackOfficeAtendimentoContext = createContext();

const tituloTela = "Backoffice Atendimento";

const defaultData = {
  backofficeId: 0,
  leadId: 0,
  hunterId: 0,
  produtoId: 0,
  vendaId: 0,
  cadastroInterno: false,
  leadNome: "",
  leadEmail: "",
  leadNumDocumento: "",
  dataProposta: "",
  numeroProposta: "",
  dataApolice: "",
  apoliceVigenciaDataIni: "",
  apoliceVigenciaDataFim: "",
  numeroApolice: "",
  seguradoraId: 0,
  consultorNome: "",
  statusEmissao: "P",
  pendencia: "",
  dataPendencia: "",
  dataLimitePendencia: "",
  erroOperador: false,
  observacao: "",
  observacaoHistorico: "",
  statusCadastro: false,
  dataCadastro: "",
  tipoTransmissao: "",
  dataRecusa: "",
  motivoRecusa: "",
  leadAuto: {},
  vendaAuto: {},
  valorLiquido: "",
  percentualComissao: "",
  tipoVeiculoId: 0,
  leadTipoNegocio: "",
  leadIdRenovacao: 0,
  backofficeSolicitante: "",
  possuiSinistro: false,
  utilizaCataoPortoNovo: false,
  valorBonusCartaoPortoNovo: "0",

};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "leadId",
    hidden: true,
  },
  {
    dataField: "leadIdRenovacao",
    headerStyle: { width: "40px" },
    sort: true,
    formatter: (cell, row) => {
      return (
        <div className="text-center">
          {cell && cell > 0 ? (
            <span
              data-toggle="tooltip"
              data-placement="top"
              title="Renovação"
              className={`badge badge-default rounded-pill pl-1 pr-1 mr-1`}
              style={{ width: "15px" }}
            >
              R
            </span>
          ) : (
            <span
              data-toggle="tooltip"
              data-placement="top"
              title="Novo Lead"
              className={`badge badge-info rounded-pill pl-1 pr-1 mr-1`}
              style={{ width: "15px" }}
            >
              N
            </span>
          )}

          {row.possuiSinistro ?
            <span
              data-toggle="tooltip"
              data-placement="top"
              title="Possui Sinistro"
              className={`badge badge-danger rounded-pill pl-1 pr-1 mr-1`}
              style={{ width: "15px" }}
            >
              S
            </span> : null
          }

        </div>
      );
    },
  },
  {
    text: "Origem",
    dataField: "cadastroInterno",
    headerStyle: { width: "50px" },
    formatter: (cell, row) => {
      return cell ? (
        <span className="badge badge-secondary">Interno</span>
      ) : (
        <span className="badge badge-primary">Atendimento</span>
      );
    },
  },
  {
    text: "Data da Proposta",
    dataField: "dataProposta",
    headerStyle: { width: "55px" },
    sort: true,
  },
  {
    text: "Num. da Proposta",
    dataField: "numeroProposta",
    headerStyle: { width: "55px" },
    sort: true,
  },
  {
    text: "Consultor",
    dataField: "consultorNome",
    headerStyle: { width: "150px" },
    sort: true,
  },
  {
    text: "Nome",
    dataField: "leadNome",
    headerStyle: { width: "150px" },
    sort: true,
    formatter: (cell, row) => {
      return (
        <HistoricoLead leadId={row.leadId} disabled={false} >
          <span className="link_primary">
            {cell}
          </span>
        </HistoricoLead>
      );
    },

  },
  {
    text: "Status da Emissão",
    dataField: "statusEmissao",
    headerStyle: { width: "55px" },
    sort: true,
    formatter: (cell, row) => {
      const statusEmissaoDesc = statusEmissaoList.find((x) => x.id === cell);

      return (
        <span className={`badge badge-${statusEmissaoDesc.class}`}>
          {statusEmissaoDesc.descricao}
        </span>
      );
    },
  },
];

const statusEmissaoList = [
  {
    id: "P",
    descricao: "Pendente",
    class: "warning",
  },
  {
    id: "E",
    descricao: "Emitido",
    class: "success",
  },
  {
    id: "R",
    descricao: "Recusado",
    class: "danger",
  },
];

const searchDataInitialValue = {
  leadNome: "",
  leadEmail: "",
  leadNumDocumento: "",
  tipoTransmissao: "",
  usuarioVenda: 0,
  usuarioBackoffice: 0,
  statusEmissao: "P",
  numeroProposta: "",
  statusCadastro: false,
  dataPropostaDe: "",
  dataPropostaAte: "",
  dataVendaDe: "",
  dataVendaAte: "",
  dataPendenciaDe: "",
  dataPendenciaAte: "",
  dataLimitePendenciaDe: "",
  dataRecusaDe: "",
  dataRecusaAte: "",
  dataLimitePendenciaAte: "",
  backofficeSolicitante: "",
  listarSomenteRenovacao: false,
};

function initialState() {
  return {
    searchData: searchDataInitialValue,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    formDisabled: true,
    consultores: [],
    usuariosBackOffice: [],
    hunters: [],
    statusEmissaoList: statusEmissaoList,
    tiposTransmissao: [],
    tiposPendencia: [],
    motivosRecusa: [],
    seguradoras: [],
    produtos: [],
    tiposNegocio: [],
    tiposVeiculo: [],
    produto: {},
  };
}

let arquivos = [];

const BackOfficeAtendimentoProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } =
    useContext(AppContext);
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {

    try {

      const [
        permissoesPaginaAux,
        usuarios,
        hunters,
        seguradoras,
        produtos,
        tiposTransmissao,
        tiposPendencia,
        motivosRecusa,
        tiposNegocio,
        tiposVeiculo] = await Promise.all([
          validarPermissoesPagina(),
          ListarUsuario({}),
          ListarHunter({}),
          ListarSeguradora({}),
          ListarProduto({}),
          ListarListBoxPorRotulo("TipoTransmissao"),
          ListarListBoxPorRotulo("TipoPendencia"),
          ListarListBoxPorRotulo("MotivoRecusa"),
          ListarListBoxPorRotulo("LeadTipoNegocio"),
          ListarTipoVeiculo()
        ]);

      const teste = usuarios.usuarios.filter(
        (usuario) => usuario.usuarioStatus === "A"
      );

      return {
        permissoesPagina: permissoesPaginaAux,
        usuarios: teste,
        seguradoras: seguradoras.seguradoras.filter(
          (seguradora) => seguradora.seguradoraStatus === "A"
        ),
        hunters: hunters.hunters.filter(
          (hunter) => hunter.hunterAtivo === "A"
        ),
        produtos: produtos.produtos.filter((item) => item.produtoAtivo === 'A'),
        tiposTransmissao: tiposTransmissao.items,
        tiposPendencia: tiposPendencia.items,
        motivosRecusa: motivosRecusa.items,
        tiposNegocio: tiposNegocio.items,
        tiposVeiculo: tiposVeiculo.tiposVeiculo,
      };
    } catch (error) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
      return;
    }
  }

  const resetScreen = () => {
    showLoading();
    arquivos = [];
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
        consultores: await dependencias.usuarios,
        usuariosBackOffice: await dependencias.usuarios,
        seguradoras: await dependencias.seguradoras,
        hunters: await dependencias.hunters,
        produtos: await dependencias.produtos,
        tiposTransmissao: await dependencias.tiposTransmissao,
        tiposPendencia: await dependencias.tiposPendencia,
        motivosRecusa: await dependencias.motivosRecusa,
        tiposNegocio: await dependencias.tiposNegocio,
        tiposVeiculo: await dependencias.tiposVeiculo,
        produto: {},
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();

    const responseListar = await ListarBackOfficeAtendimento(values.searchData);

    if (!responseListar.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }
    let backOfficeList = await responseListar.backOffice;

    backOfficeList.map((backOffice) => {
      backOffice.Id = backOffice.leadId;
      return backOffice;
    });

    setValues({
      ...values,
      resultSearch: backOfficeList,
    });

    if (backOfficeList.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const getDetailHandler = async (id, definirVisibilidade = () => { }) => {
    showLoading();
    const dependencias = await loadDependences();
    setTituloTela(tituloTela);

    // eslint-disable-next-line eqeqeq
    if (id != 0) {
      const responseDetalhes = await DetalhesBackOfficeAtendimento(id);

      const { success, backOffice } = await responseDetalhes;

      if (!success) {
        showMessage("Erro", responseDetalhes.message);
        responseDetalhes.errors.map((erro) =>
          showMessage("Erro", erro.errorMessage)
        );
        setValues({
          ...values,
          data: defaultData,
          permissoesPagina: await dependencias.permissoesPagina,
          consultores: [],
          usuariosBackOffice: [],
          hunters: [],
          produtos: [],
          seguradoras: [],
          tiposTransmissao: [],
          tiposPendencia: [],
          motivosRecusa: [],
          tiposNegocio: [],
          tiposVeiculo: [],
          produto: {},
        });
        hideLoading();
        return;
      }

      const responseListarArquivosLead = await ListarArquivosLead(
        backOffice.leadId
      );

      if (!responseListarArquivosLead.success) {
        showMessage("Erro", responseListarArquivosLead.message);
        responseListarArquivosLead.errors.map((erro) =>
          showMessage("Erro", erro.errorMessage)
        );
        arquivos = [];
      }

      arquivos = await responseListarArquivosLead.arquivos;

      const responseDetalhesProduto = await DetalhesProduto(
        backOffice.produtoId
      );

      if (!responseDetalhesProduto.success) {
        showMessage("Erro", responseDetalhesProduto.message);
        responseDetalhesProduto.errors.map((erro) =>
          showMessage("Erro", erro.errorMessage)
        );
      }

      definirVisibilidade(backOffice.statusEmissao);

      setValues({
        ...values,
        data: backOffice,
        permissoesPagina: await dependencias.permissoesPagina,
        consultores: await dependencias.usuarios,
        usuariosBackOffice: await dependencias.usuarios,
        hunters: await dependencias.hunters,
        seguradoras: await dependencias.seguradoras,
        produtos: await dependencias.produtos,
        tiposTransmissao: await dependencias.tiposTransmissao,
        tiposPendencia: await dependencias.tiposPendencia,
        motivosRecusa: await dependencias.motivosRecusa,
        produto: await responseDetalhesProduto.produto,
        tiposNegocio: await dependencias.tiposNegocio,
        tiposVeiculo: await dependencias.tiposVeiculo,
      });
    } else {
      const responseIncluirLeadInterno = await IncluirLeadInterno();

      definirVisibilidade(defaultData.statusEmissao);

      setValues({
        ...values,
        data: {
          ...defaultData,
          leadId: responseIncluirLeadInterno.leadId,
          cadastroInterno: true,
        },
        formDisabled: false,
        consultores: await dependencias.usuarios,
        usuariosBackOffice: await dependencias.usuarios,
        hunters: await dependencias.hunters,
        seguradoras: await dependencias.seguradoras,
        produtos: await dependencias.produtos,
        tiposTransmissao: await dependencias.tiposTransmissao,
        tiposPendencia: await dependencias.tiposPendencia,
        motivosRecusa: await dependencias.motivosRecusa,
        tiposNegocio: await dependencias.tiposNegocio,
        tiposVeiculo: await dependencias.tiposVeiculo,
        produto: {},
      });
    }
    hideLoading();
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const saveHandler = async () => {
    showLoading();
    const responseIncluir = await ProcessarBackOfficeAtendimento(values.data);
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao processar o registro: ${responseIncluir.message}`
      );
      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Salvo com sucesso.");
    history.goBack();
  };

  const cancelHandler = () => {
    arquivos = [];
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  const uploadAnexo = async (arquivo) => {
    showLoading("Aguarde, Importando Arquivo.");
    const responseUploadAnexosLead = await UploadAnexosLead(
      values.data.leadId,
      arquivo.nome,
      arquivo.arquivo
    );
    if (!responseUploadAnexosLead.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao finalizar o atendimento: ${responseUploadAnexosLead.message
          ? responseUploadAnexosLead.message
          : "Indefinido"
        }`
      );
      if (responseUploadAnexosLead.errors) {
        responseUploadAnexosLead.errors.map((erro) =>
          showMessage("Erro", erro.errorMessage)
        );
      }
      return;
    }
    arquivos = responseUploadAnexosLead.arquivos;

    hideLoading();
    showMessage("Sucesso", "Arquivo Importado com sucesso.");
  };

  const removerAnexo = async (arquivoId) => {
    showLoading("Aguarde, Removendo o arquivo.");
    const responseRemoverAnexoLead = await RemoverAnexosLead(
      values.data.leadId,
      arquivoId
    );
    if (!responseRemoverAnexoLead.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao finalizar o atendimento: ${responseRemoverAnexoLead.message
          ? responseRemoverAnexoLead.message
          : "Indefinido"
        }`
      );
      if (responseRemoverAnexoLead.errors) {
        responseRemoverAnexoLead.errors.map((erro) =>
          showMessage("Erro", erro.errorMessage)
        );
      }
      return;
    }
    arquivos = await responseRemoverAnexoLead.arquivos;
    hideLoading();
    showMessage("Sucesso", "Arquivo Removido.");
  };

  const downloadAnexo = async (arquivoId) => {
    showLoading("Aguarde, Baixando Arquivo.");
    await DownloadArquivo(arquivoId);
    hideLoading();
  };

  const obterBonusCartaoPortoNovo = async () => {
    try {
      let bonusCartaoPortoNovo = 0;
      const response = await ListarCatalogo({ catalogoRotulo: "ComisCartaoPortoNovo" });

      if (!response?.success || !response.catalogos || response.catalogos.length === 0) {
        return bonusCartaoPortoNovo;
      }

      const configCartaoPortoNovo = response.catalogos[0];
      const bonus = parseFloat(configCartaoPortoNovo.catalogo001);

      bonusCartaoPortoNovo = isNaN(bonus) ? 0 : bonus;

      return bonusCartaoPortoNovo;
    } catch (error) {
      console.error("Error fetching bonus:", error);
      return 0;
    }
  };

  return (
    <BackOfficeAtendimentoContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
        uploadAnexo,
        removerAnexo,
        downloadAnexo,
        arquivos,
        showMessage,
        obterBonusCartaoPortoNovo
      }}
    >
      {children}
    </BackOfficeAtendimentoContext.Provider>
  );
};

export default BackOfficeAtendimentoProvider;
