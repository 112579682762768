import $ from "jquery";
import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import Navbar from "../../Components/NavBar/NavBar";
import SideBar from "../../Components/SideBar/SideBar";
import PrivateRoute from "../../Routes/PrivateRoutes";
import PagesDashBoardIndex from "../DashBoard/Index";
import PagesHomeIndex from "../Home/Index";
import PagesFavoritosIndex from "../Favoritos/Index";
import PagesOperacoesAgendaIndex from "../Operacoes/Agenda/Index";
import PagesOperacoesAtendimentoAtendimentoIndex from "../Operacoes/Atendimento/Atendimento/Index";
import PagesOperacoesAtendimentoFilaAtendimentoIndex from "../Operacoes/Atendimento/FilaAtendimento/Index";
import PagesOperacoesBackOfficeAtendimentoIndex from "../Operacoes/BackOffice/Atendimento/Index";
import PagesOperacoesBackOfficeCarsystemApiConsultaHdiIndex from "../Operacoes/Cadastros/CarsystemApiConsultaHdi/Index";
import PagesCadastrosBaseProspectIndex from "../Operacoes/Cadastros/BaseProspect/Index";
import PagesCadastrosCampanhaIndex from "../Operacoes/Cadastros/Campanha/Index";
import PagesCadastrosEquipeIndex from "../Operacoes/Cadastros/Equipe/Index";
import PagesCadastrosFeriadoIndex from "../Operacoes/Cadastros/Feriado/Index";
import PagesCadastrosFiltroDeDadosIndex from "../Operacoes/Cadastros/FiltroDeDados/Index";
import PagesCadastrosFluxoEventosIndex from "../Operacoes/Cadastros/FluxoEventos/Index";
import PagesCadastrosFornecedorLeadIndex from "../Operacoes/Cadastros/FornecedorLead/Index";
import PagesCadastrosGrupoUsuarioIndex from "../Operacoes/Cadastros/GrupoUsuario/Index";
import PagesCadastrosJornadaTrabalhoIndex from "../Operacoes/Cadastros/JornadaTrabalho/Index";
import PagesCadastrosJornadaTrabalhoFaltaIndex from "../Operacoes/Cadastros/JornadaTrabalhoFalta/Index";
import PagesCadastrosLandingPageIndex from "../Operacoes/Cadastros/LandingPage/Index";
import PagesCadastrosMotivoPerdaIndex from "../Operacoes/Cadastros/MotivoPerda/Index";
import PagesCadastrosOrigemLeadIndex from "../Operacoes/Cadastros/OrigemLead/Index";
import PagesCadastrosProdutoIndex from "../Operacoes/Cadastros/Produto/Index";
import PagesCadastrosSegmentacaoPublicoIndex from "../Operacoes/Cadastros/SegmentacaoPublico/Index";
import PagesCadastrosSeguradoraIndex from "../Operacoes/Cadastros/Seguradora/Index";
import PagesCadastrosTemplateEmailIndex from "../Operacoes/Cadastros/TemplateEmail/Index";
import PagesCadastrosTemplateSmsIndex from "../Operacoes/Cadastros/TemplateSms/Index";
import PagesCadastrosTemplateWhatsAppBlipIndex from "../Operacoes/Cadastros/TemplateWhatsAppBlip/Index";
import PagesCadastrosTipoAgendamentoIndex from "../Operacoes/Cadastros/TipoAgendamento/Index";
import PagesCadastrosTipoContatoIndex from "../Operacoes/Cadastros/TipoContato/Index";
import PagesCadastrosTipoNegociacaoIndex from "../Operacoes/Cadastros/TipoNegociacao/Index";
import PagesCadastrosUsuarioIndex from "../Operacoes/Cadastros/Usuario/Index";
import PagesOperacoesCobrancaIndex from "../Operacoes/Cobranca/Index";
import PagesOperacoesImportarPropostaIndex from "../Operacoes/DadosSeguradora/ImportarDadosSeguradora/Index";
import PagesOperacoesEndossoIndex from "../Operacoes/Endosso/Index";
import PagesOperacoesDistribuicaoLeadIndex from "../Operacoes/Lead/DistribuicaoLead/Index";
import PagesOperacoesImportarLeadIndex from "../Operacoes/Lead/ImportarLead/Index";
import PagesOperacoesLeadManualIndex from "../Operacoes/Lead/LeadManual/Index";
import PagesMotorEventosIndex from "../Operacoes/MotorEventos/Index";
import PagesOperacoesSinistroIndex from "../Operacoes/Sinistro/Index";
import PagesCadastrosLojaVirtualIndex from "../Operacoes/Cadastros/LojaVirtual/Index";
import PagesCadastrosTarefasExecutarIndex from "../Operacoes/Cadastros/TarefasExecutar/Index";
import PagesCadastrosPipeLineIndex from "../Operacoes/Cadastros/PipeLine/Index";
import PagesClienteHistoricoClienteIndex from "../Operacoes/Cliente/HistoricoCliente/Index";
import ImportarCobrancaIndex from "../Operacoes/Cobranca/ImportarCobranca/Index";
import SemAcessoIndex from "../SemAcesso/Index";
import PagesCadastrosPreLeadCotacaoIndex from "../Operacoes/Cadastros/PreLeadCotacao/Index";
import PagesAprovacaoVendasAtendimentoIndex from "../Operacoes/Cadastros/AprovacaoVendasAtendimento/Index";
import "./MasterPage.css";

// RELATORIOS
import PagesRelatoriosModuloIndex from "../Operacoes/Relatorios/RelatoriosModulo/Index";
import PagesNegociosFechadosIndex from "../Operacoes/Relatorios/NegociosFechados/Index";
import PagesUltimoAtendimentoLeadIndex from "../Operacoes/Relatorios/UltimoAtendimentoLead/Index";
import PagesConversaoLeadIndex from "../Operacoes/Relatorios/ConversaoLead/Index";


const MasterPage = () => {
  useEffect(() => {
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex d-md-block flex-nowrap wrapper" id="wrapper">
      <SideBar />
      <div id="page-content-wrapper">
        <Navbar />

        <Switch>
          <PrivateRoute exact path="/SemAcesso" component={SemAcessoIndex} />
          <PrivateRoute exact path={`/`} component={PagesHomeIndex} />
          {/* <PrivateRoute exact path={`/Home`} component={PagesHomeIndex} /> */}
          <PrivateRoute exact path={`/Home`} component={PagesFavoritosIndex} />
          <PrivateRoute
            exact
            path={`/DashBoard/:rotulo`}
            component={PagesDashBoardIndex}
          />
          <PrivateRoute
            path={`/Cadastros/GrupoUsuario`}
            component={PagesCadastrosGrupoUsuarioIndex}
          />
          <PrivateRoute
            path={`/Cadastros/Usuario`}
            component={PagesCadastrosUsuarioIndex}
          />
          <PrivateRoute
            exact
            path={`/Leads/DistribuicaoLead`}
            component={PagesOperacoesDistribuicaoLeadIndex}
          />
          <PrivateRoute
            exact
            path={`/Leads/CadastroManual`}
            component={PagesOperacoesLeadManualIndex}
          />
          <PrivateRoute
            exact
            path={`/Leads/Importar`}
            component={PagesOperacoesImportarLeadIndex}
          />
          <PrivateRoute
            exact
            path={`/DadosSeguradora/Importar`}
            component={PagesOperacoesImportarPropostaIndex}
          />
          <PrivateRoute
            path={`/Cadastros/Equipe`}
            component={PagesCadastrosEquipeIndex}
          />
          <PrivateRoute
            path={`/Cadastros/Produto`}
            component={PagesCadastrosProdutoIndex}
          />
          <PrivateRoute
            path={`/Cadastros/Fornecedorlead`}
            component={PagesCadastrosFornecedorLeadIndex}
          />
          <PrivateRoute
            path={`/Cadastros/Seguradora`}
            component={PagesCadastrosSeguradoraIndex}
          />
          <PrivateRoute
            path={`/Cadastros/Origemlead`}
            component={PagesCadastrosOrigemLeadIndex}
          />
          <PrivateRoute
            path={`/Cadastros/LandingPage`}
            component={PagesCadastrosLandingPageIndex}
          />
          <PrivateRoute
            path={`/Cadastros/JornadaTrabalho`}
            component={PagesCadastrosJornadaTrabalhoIndex}
          />
          <PrivateRoute
            path={`/Cadastros/JornadaTrabalhoFalta`}
            component={PagesCadastrosJornadaTrabalhoFaltaIndex}
          />
          <PrivateRoute
            path={`/Cadastros/Feriado`}
            component={PagesCadastrosFeriadoIndex}
          />
          <PrivateRoute
            path={`/Cadastros/MotivoPerda`}
            component={PagesCadastrosMotivoPerdaIndex}
          />
          <PrivateRoute
            path={`/Cadastros/TipoContato`}
            component={PagesCadastrosTipoContatoIndex}
          />
          <PrivateRoute
            path={`/Cadastros/TipoAgendamento`}
            component={PagesCadastrosTipoAgendamentoIndex}
          />
          <PrivateRoute
            path={`/Cadastros/BaseProspect`}
            component={PagesCadastrosBaseProspectIndex}
          />
          <PrivateRoute
            path={`/Cadastros/SegmentacaoPublico`}
            component={PagesCadastrosSegmentacaoPublicoIndex}
          />

          <PrivateRoute
            path={`/Cadastros/TemplateEmail`}
            component={PagesCadastrosTemplateEmailIndex}
          />

          <PrivateRoute
            path={`/Cadastros/TemplateSms`}
            component={PagesCadastrosTemplateSmsIndex}
          />

          <PrivateRoute
            path={`/Cadastros/TemplateWhatsAppBlip`}
            component={PagesCadastrosTemplateWhatsAppBlipIndex}
          />

          <PrivateRoute
            path={`/Cadastros/Campanha`}
            component={PagesCadastrosCampanhaIndex}
          />

          <PrivateRoute
            path={`/Agenda`}
            component={PagesOperacoesAgendaIndex}
          />
          <PrivateRoute
            path={`/Atendimento/FilaAtendimento`}
            component={PagesOperacoesAtendimentoFilaAtendimentoIndex}
          />

          <PrivateRoute
            exact
            path={`/Atendimento/Atendimento`}
            component={PagesOperacoesAtendimentoAtendimentoIndex}
          />
          <PrivateRoute
            exact
            path={`/Atendimento/Atendimento/`}
            component={PagesOperacoesAtendimentoAtendimentoIndex}
          />
          <PrivateRoute
            path={`/Atendimento/Atendimento/:filaAtendimentoId`}
            component={PagesOperacoesAtendimentoAtendimentoIndex}
          />
          <PrivateRoute
            path={`/BackOffice/Atendimento`}
            component={PagesOperacoesBackOfficeAtendimentoIndex}
          />
          <PrivateRoute
            path={`/BackOffice/CarsystemHdi`}
            component={PagesOperacoesBackOfficeCarsystemApiConsultaHdiIndex}
          />
          <PrivateRoute
            path={`/BackOffice/AprovacaoVendasAtendimento`}
            component={PagesAprovacaoVendasAtendimentoIndex}
          />
          <PrivateRoute
            path={`/Cobranca/Cobranca`}
            component={PagesOperacoesCobrancaIndex}
          />
          <PrivateRoute
            path={`/Cobranca/ImportarCobranca`}
            component={ImportarCobrancaIndex}
          />
          <PrivateRoute
            path={`/Endosso/Endosso`}
            component={PagesOperacoesEndossoIndex}
          />

          <PrivateRoute
            path={`/Sinistro/Sinistro`}
            component={PagesOperacoesSinistroIndex}
          />

          <PrivateRoute
            path={`/Processos/MotorEventos`}
            component={PagesMotorEventosIndex}
          />

          <PrivateRoute
            path={`/Cadastros/FiltroDados`}
            component={PagesCadastrosFiltroDeDadosIndex}
          />

          <PrivateRoute
            path={`/Cadastros/FluxoEventos`}
            component={PagesCadastrosFluxoEventosIndex}
          />

          <PrivateRoute
            path={`/Cadastros/TipoNegociacao`}
            component={PagesCadastrosTipoNegociacaoIndex}
          />

          <PrivateRoute
            path={`/Cadastros/LojaVirtual`}
            component={PagesCadastrosLojaVirtualIndex}
          />


          <PrivateRoute
            path={`/Cadastros/TarefasExecutar`}
            component={PagesCadastrosTarefasExecutarIndex}
          />


          <PrivateRoute
            path={`/Cadastros/PipeLine`}
            component={PagesCadastrosPipeLineIndex}
          />


          <PrivateRoute
            path={`/HistoricoCliente`}
            component={PagesClienteHistoricoClienteIndex}
          />

          <PrivateRoute
            path={`/Cadastros/PreLeadCotacao`}
            component={PagesCadastrosPreLeadCotacaoIndex}
          />

          {/* RELATORIOS */}


          <PrivateRoute
            path={`/Relatorios/NegociosFechados`}
            component={PagesNegociosFechadosIndex}
          />

          <PrivateRoute
            path={`/Relatorios/UltimoAtendimentoLead`}
            component={PagesUltimoAtendimentoLeadIndex}
          />

          <PrivateRoute
            path={`/Relatorios/ConversaoLead`}
            component={PagesConversaoLeadIndex}
          />

          <PrivateRoute
            path={`/Relatorios`}
            component={PagesRelatoriosModuloIndex}
          />

        </Switch>
      </div>
    </div>
  );
};

export default MasterPage;
