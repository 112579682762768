/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext, Fragment } from "react";
import AppContext from "../../../../Store/AppContext";
import {
  ListarFilaAtendimentoParaAprovar,
  AprovarVendaAtendimento,
} from "../../../../Services/Atendimento";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
export const AprovacaoVendasAtendimentoContext = createContext();

const tituloTela = "Aprovacão de Vendas";

const defaultData = {
  filaAtendimentoId: 0,
  vendaReprovada: "",
  motivoReprovaVenda: "",
};

const searchData = {
  vendaDataHoraDe: "",
  vendaDataHoraAte: "",
};

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    itensModificados: [],
    data: defaultData,
    formDisabled: true,
  };
}

const AprovacaoVendasAtendimentoProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());

  async function loadDependences() {

    let [
      permissoesPaginaAux] = await Promise.all(
        [
          validarPermissoesPagina(),
        ]
      );

    return { permissoesPagina: permissoesPaginaAux };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        ...await dependencias
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    const responseListarFilaAtendimentoParaAprovar = await ListarFilaAtendimentoParaAprovar(
      values.searchData
    );

    if (!responseListarFilaAtendimentoParaAprovar.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const resultSearchData = await responseListarFilaAtendimentoParaAprovar.vendasParaAprovar;

    resultSearchData.forEach(item => {
      item.Id = item.filaAtendimentoId;
      item.foiModificado = false;
      item.vendaReprovada = "";
      item.motivoReprovaVenda = "";
    });

    setValues({
      ...values,
      resultSearch: resultSearchData,
      itensModificados: []
    });


    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };



  const updateData = async () => {
    showLoading();
    const responseAtualizar = await AprovarVendaAtendimento(values.itensModificados);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
  };

  const saveHandler = async () => {
    await updateData();
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  function onChangeInputPadraoGrid(event) {

    const { id, name, value } = event.target;
    var campo = name.split("_")[0];
    var idLinha = id.split("_")[1];

    let listData = values.resultSearch;

    listData.forEach(item => {
      if (item.filaAtendimentoId == idLinha) {
        switch (campo) {
          case "motivoReprovaVenda":
            item.motivoReprovaVenda = value;
            break;
          case "vendaReprovada":
            item.vendaReprovada = value;
            item.motivoReprovaVenda = item.vendaReprovada === "S" ? item.motivoReprovaVenda : "";
            break;

          default:
            break;
        }
        item.foiModificado = true;
      }
    });

    let itensModificados = listData.filter(item => item.foiModificado);
    setValues({
      ...values,
      resultSearch: listData,
      itensModificados
    });
  }

  return (
    <AprovacaoVendasAtendimentoContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        saveHandler,
        onChangeInputPadraoGrid
      }}
    >
      {children}
    </AprovacaoVendasAtendimentoContext.Provider>
  );
};

export default AprovacaoVendasAtendimentoProvider;
