import React, { Fragment, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Apresentacao from "../../../../../Components/Atendimento/Apresentacao/Apresentacao";
import CarrocelGraficos from "../../../../../Components/Atendimento/CarrocelGraficos/CarrocelGraficos";
import DetalhesLead from "../../../../../Components/Atendimento/DetalhesLead/DetalhesLead";
import Finalizacao from "../../../../../Components/Atendimento/Finalizacao/Finalizacao";
import Formulario from "../../../../../Components/Atendimento/Formulario/Formulario";
import InformacoesComplementares from "../../../../../Components/Atendimento/InformacoesComplementares/InformacoesComplementares";
import SemAtendimento from "../../../../../Components/Atendimento/SemAtendimento/SemAtendimento";
import TempoAtendimento from "../../../../../Components/Atendimento/TempoAtendimento/TempoAtendimento";
import PermormanceConsultor from "../../../../../Components/Graficos/PermormanceConsultor/PermormanceConsultor";
import { AtendimentoContext } from "../AtendimentoContext";

const Atendimento = () => {
  const {
    atendimentoValues,
    iniciarAtendimento,
    buscaProximoAtendimentoDaFila,
    finalizacaoHandler,
    obterTempoAtendimento,
  } = useContext(AtendimentoContext);

  let { filaAtendimentoId } = useParams();

  useEffect(() => {
    buscaProximoAtendimentoDaFila(filaAtendimentoId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Apresentacao
        totalContatos={
          atendimentoValues.dadosAtendimento.filaAtendimento.totalContatos
        }
        dataRecebimentoLead={
          atendimentoValues.dadosAtendimento.filaAtendimento.dataRecebimentoLead
        }
        horaRecebimentoLead={
          atendimentoValues.dadosAtendimento.filaAtendimento.horaRecebimentoLead
        }
        dataVencimentoApolice={
          atendimentoValues.dadosAtendimento.lead.leadDataVencApolice
        }
        cidade={atendimentoValues.dadosAtendimento.lead.cidade}
        uf={atendimentoValues.dadosAtendimento.lead.uf}
        onClick={iniciarAtendimento}
        filaAtendimentoId={filaAtendimentoId}
        temperaturaValor={
          atendimentoValues.dadosAtendimento.lead.leadScoringSystem
        }
        temperaturaDescricao={atendimentoValues.descricaoTemperatura(
          atendimentoValues.dadosAtendimento.lead.leadScoringSystem
        )}
        tipoVeiculoDescricao={
          atendimentoValues.dadosAtendimento.lead.tipoVeiculoDescricao
        }
        tipoNegocio={atendimentoValues.dadosAtendimento.lead.leadTipoNegocio}
        leadIdRenovacao={
          atendimentoValues.dadosAtendimento.lead.leadIdRenovacao
        }
        leadPreferenciaContato={
          atendimentoValues.dadosAtendimento.lead.leadPreferenciaContato
        }
        leadDataHoraAgendaContato={
          atendimentoValues.dadosAtendimento.lead.leadDataHoraAgendaContato
        }
        leadObservacaoLead={
          atendimentoValues.dadosAtendimento.lead.leadObservacaoLead
        }
        lojaVirtualNome={
          atendimentoValues.dadosAtendimento.lead.lojaVirtualNome
        }
        lojaVirtualImagem={
          atendimentoValues.dadosAtendimento.lead.lojaVirtualImagem
        }
        motivoReprovaVenda={
          atendimentoValues.dadosAtendimento.filaAtendimento.motivoReprovaVenda
        }
      />
      <Finalizacao proximoAtendimentoHandler={finalizacaoHandler} />
      <SemAtendimento />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-9">
            <InformacoesComplementares
              totalContatos={
                atendimentoValues.dadosAtendimento.filaAtendimento.totalContatos
              }
              dataRecebimentoLead={
                atendimentoValues.dadosAtendimento.filaAtendimento
                  .dataRecebimentoLead
              }
              horaRecebimentoLead={
                atendimentoValues.dadosAtendimento.filaAtendimento
                  .horaRecebimentoLead
              }
              dataVencimentoApolice={
                atendimentoValues.dadosAtendimento.lead.leadDataVencApolice
              }
              cidade={atendimentoValues.dadosAtendimento.lead.cidade}
              uf={atendimentoValues.dadosAtendimento.lead.uf}
              historicoAtendimento={
                atendimentoValues.dadosAtendimento.historicoAtendimento
              }
              temperaturaDescricao={atendimentoValues.descricaoTemperatura(
                atendimentoValues.dadosAtendimento.lead.leadScoringSystem
              )}
              lead={atendimentoValues.dadosAtendimento.lead}
              atendimentoDadosUltimaContratacao={
                atendimentoValues.dadosAtendimento
                  .atendimentoDadosUltimaContratacao
              }
            />
            <Formulario context={AtendimentoContext} />
          </div>
          <div className="col-12 col-md-3">
            <DetalhesLead
              temperaturaValor={
                atendimentoValues.dadosAtendimento.lead.leadScoringSystem
              }
              temperaturaDescricao={atendimentoValues.descricaoTemperatura(
                atendimentoValues.dadosAtendimento.lead.leadScoringSystem
              )}
              fornecedorLead={
                atendimentoValues.dadosAtendimento.lead.fornecedorLead
              }
              landingPageNome={
                atendimentoValues.dadosAtendimento.lead.landingPageNome
              }
              landingPageUrl={
                atendimentoValues.dadosAtendimento.lead.landingPageUrl
              }
              tipoLead={atendimentoValues.dadosAtendimento.lead.tipoLead}
              origemLeadNome={
                atendimentoValues.dadosAtendimento.lead.origemLeadNome
              }
              leadDataInteracao={
                atendimentoValues.dadosAtendimento.lead.leadDataInteracao
              }
              leadClassificacao={
                atendimentoValues.dadosAtendimento.lead.leadClassificacao
              }
              keyWords={atendimentoValues.dadosAtendimento.lead.keyWords}
              leadIdRenovacao={
                atendimentoValues.dadosAtendimento.lead.leadIdRenovacao
              }
              produto={
                atendimentoValues.dadosAtendimento.produto
              }
              lojaVirtualNome={
                atendimentoValues.dadosAtendimento.lead.lojaVirtualNome
              }
              lojaVirtualImagem={
                atendimentoValues.dadosAtendimento.lead.lojaVirtualImagem
              }
            />
            <TempoAtendimento
              atendimentoIniciado={atendimentoValues.atendimentoIniciado}
              tempoAtendimento={
                atendimentoValues.dadosAtendimento.tempoAtendimento
              }
              obterTempoAtendimento={obterTempoAtendimento}
            />
            {atendimentoValues.dadosGraficos ? (
              <PermormanceConsultor
                dataPerformace={atendimentoValues.dadosGraficos}
              >
                <CarrocelGraficos
                  dataPerformace={atendimentoValues.dadosGraficos}
                  meuRendimento={55}
                />
              </PermormanceConsultor>
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Atendimento;
